.smallImage {
  width: 45px;
  height: auto;
  display: inline-block;
}

.userName {
  display: inline-block;
  font-weight: normal;
  margin: 0px 0px 0px 15px;
  font-size: 1.6rem;
}

.navWrapper {
  display: flex;
  padding: 8px;
  flex-wrap: wrap;
  justify-content: space-around;
  min-height: 60px;
  align-items: center;
}

.navList {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.listItem > a {
  font-size: 1.8rem;
  text-decoration: none;
  font-weight: 700;
  color: var(--dl-color-custom-foreground);
  padding: 10px 20px;
}

.listItem > a:hover {
  color: var(--dl-color-custom-main-background);
}
