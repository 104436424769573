:root {
  --dl-color-custom-main-background: #cbd2d3;
  --dl-color-custom-foreground: #000;
  --dl-color-custom-content: #000;
  --dl-color-custom-border: #383030;
  --dl-color-custom-code: #f0f0f0;
  --dl-color-custom-links: #0275d8ff;
  --dl-color-gray-500: #595959;
  --dl-color-gray-700: #999999;
  --dl-color-gray-900: #d9d9d9;
  --dl-size-size-unit: 1rem;
  --dl-color-danger-300: #a22020;
  --dl-color-danger-500: #bf2626;
  --dl-color-danger-700: #e14747;
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #ffffff;
  --dl-space-space-unit: 1rem;
  --dl-color-primary-100: #003eb3;
  --dl-color-primary-300: #0074f0;
  --dl-color-primary-500: #14a9ff;
  --dl-color-primary-700: #85dcff;
  --dl-color-success-300: #199033;
  --dl-color-success-500: #32a94c;
  --dl-color-success-700: #4cc366;
  --dl-size-size-halfunit: 0.5rem;
  --dl-radius-radius-round: 50%;
  --dl-size-size-doubleunit: 2rem;
  --dl-size-size-tripleunit: 3rem;
  --dl-space-space-halfunit: 0.5rem;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-doubleunit: 2rem;
  --dl-space-space-tripleunit: 3rem;
}

@media (prefers-color-scheme: dark) {
  :root {
    --dl-color-custom-main-background: #000;
    --dl-color-custom-foreground: #fff;
    --dl-color-custom-content: #fff;
    --dl-color-custom-border: #383030;
    --dl-color-custom-code: #252424;
  }
}

:focus {
  outline: 2px solid var(--dl-color-custom-foreground);
  border-radius: 4px;
}

html {
  font-size: 62.5%;
  color: var(--dl-color-custom-content);
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  background-color: var(--dl-color-custom-main-background);
  color: var(--dl-color-custom-content);
  font-size: 1.8rem;
  line-height: 1.3;
}

strong {
  color: var(--dl-color-custom-foreground);
}

em {
  color: var(--dl-color-custom-foreground);
}

pre {
  overflow-x: scroll !important;
  border-radius: 4px;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  font-size: 1.4rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

li {
  margin-top: 5px;
  margin-bottom: 5px;
}

a {
  cursor: pointer;
  color: var(--dl-color-custom-foreground);
  text-decoration: underline;
  font-weight: bold;
  font-size: 1.6rem;
  padding-bottom: 3px;
  padding: 2px;
  overflow-wrap: break-word;
}

a:hover {
  background-color: var(--dl-color-custom-foreground);
  color: var(--dl-color-custom-main-background);
  text-decoration: none;
  border-bottom: none;
}

a > h2 {
  color: var(--dl-color-custom-foreground);
  background-color: inherit;
  display: inline-block;
  text-decoration: none;
  margin-bottom: 0px;
  text-align: left;
}

a:hover > h2 {
  color: var(--dl-color-custom-main-background);
  margin-bottom: 0px;
}

h1,
h2,
h3 {
  padding: 5px;
  color: var(--dl-color-custom-main-background);
  background-color: var(--dl-color-custom-foreground);
  font-weight: 700;
  width: auto;
  display: inline-block;
}

p {
  margin-top: 2rem;
  font-size: 1.6rem;
}

blockquote {
  border-left: 2px solid var(--dl-color-custom-foreground);
  padding-left: 10px;
  overflow-x: scroll;
}

.landingImage {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

figcaption {
  text-align: center;
  display: block;
  margin-top: 1rem;
  font-size: 1.4rem;
}

article {
  text-align: left;
}

video {
  width: 100%;
}

.splitPost {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.splitPost > p {
  flex: 1 1 0px;
}

.splitPost > div {
  flex: 1 1 0px;
}

.splitPost > figure {
  flex: 1 1 0px;
}

.bold {
  font-weight: bold;
}

@media (max-width: 991px) {
  .splitPost {
    display: block;
  }
  .landingImage {
    height: auto;
  }
}
