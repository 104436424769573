.blogDescription {
  text-align: left;
}

.aritcleGrid {
  display: grid;
  text-align: left;
  grid-gap: 40px;
  grid-template-columns: 1fr 1fr;
}

.blogsWrapper {
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 100px;
  width: 50%;
}

.landingImage {
  width: 100%;
  height: 350px;
  object-fit: cover;
}

.blogsHeading {
  text-align: center;
  font-size: 1.6rem;
}

.blogsDescription {
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 768px) {
  .aritcleGrid {
    grid-template-columns: 1fr;
  }

  .blogsWrapper {
    width: 80%;
  }
}
