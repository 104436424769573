.post_wrapper {
  width: 60%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 100px;
  line-height: 1.5;
}

.date {
  font-size: 1.4rem;
  padding-bottom: 10px;
  border-bottom: 0.3px solid var(--dl-color-custom-content);
}

.post_description {
  margin-bottom: 10px;
}

.post_image_holder {
  position: relative;
}

.post_landing_loader {
  top: 0px;
  z-index: 1;
  width: 100%;
  height: 400px;
  margin-top: 15px;
  position: absolute;
  background-color: #f4f4f4;
}

.post_landing_image {
  width: 100%;
  margin-top: 15px;
  object-fit: cover;
  object-position: top;
  max-height: 400px;
  object-fit: cover;
  object-position: center;
}

.post_content {
  margin-top: 20px;
  padding-bottom: 30px;
  max-width: 80%;
  margin: auto;
}

@media (max-width: 767px) {
  .post_wrapper {
    width: 90%;
    padding: 0% 5%;
  }

  .post_content {
    margin-top: 20px;
    padding-bottom: 30px;
    max-width: 90%;
    margin: auto;
  }
}
