:root {
  --dl-color-custom-main-background: #cbd2d3;
  --dl-color-custom-foreground: #000;
  --dl-color-custom-content: #000;
  --dl-color-custom-border: #383030;
  --dl-color-custom-code: #f0f0f0;
  --dl-color-custom-links: #0275d8ff;
  --dl-color-gray-500: #595959;
  --dl-color-gray-700: #999999;
  --dl-color-gray-900: #d9d9d9;
  --dl-size-size-unit: 1rem;
  --dl-color-danger-300: #a22020;
  --dl-color-danger-500: #bf2626;
  --dl-color-danger-700: #e14747;
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #ffffff;
  --dl-space-space-unit: 1rem;
  --dl-color-primary-100: #003eb3;
  --dl-color-primary-300: #0074f0;
  --dl-color-primary-500: #14a9ff;
  --dl-color-primary-700: #85dcff;
  --dl-color-success-300: #199033;
  --dl-color-success-500: #32a94c;
  --dl-color-success-700: #4cc366;
  --dl-size-size-halfunit: 0.5rem;
  --dl-radius-radius-round: 50%;
  --dl-size-size-doubleunit: 2rem;
  --dl-size-size-tripleunit: 3rem;
  --dl-space-space-halfunit: 0.5rem;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-doubleunit: 2rem;
  --dl-space-space-tripleunit: 3rem;
}

@media (prefers-color-scheme: dark) {
  :root {
    --dl-color-custom-main-background: #000;
    --dl-color-custom-foreground: #fff;
    --dl-color-custom-content: #fff;
    --dl-color-custom-border: #383030;
    --dl-color-custom-code: #252424;
  }
}

:focus {
  outline: 2px solid #000;
  outline: 2px solid var(--dl-color-custom-foreground);
  border-radius: 4px;
}

html {
  font-size: 62.5%;
  color: #000;
  color: var(--dl-color-custom-content);
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  background-color: #cbd2d3;
  background-color: var(--dl-color-custom-main-background);
  color: #000;
  color: var(--dl-color-custom-content);
  font-size: 1.8rem;
  line-height: 1.3;
}

strong {
  color: #000;
  color: var(--dl-color-custom-foreground);
}

em {
  color: #000;
  color: var(--dl-color-custom-foreground);
}

pre {
  overflow-x: scroll !important;
  border-radius: 4px;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  font-size: 1.4rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

li {
  margin-top: 5px;
  margin-bottom: 5px;
}

a {
  cursor: pointer;
  color: #000;
  color: var(--dl-color-custom-foreground);
  text-decoration: underline;
  font-weight: bold;
  font-size: 1.6rem;
  padding-bottom: 3px;
  padding: 2px;
  overflow-wrap: break-word;
}

a:hover {
  background-color: #000;
  background-color: var(--dl-color-custom-foreground);
  color: #cbd2d3;
  color: var(--dl-color-custom-main-background);
  text-decoration: none;
  border-bottom: none;
}

a > h2 {
  color: #000;
  color: var(--dl-color-custom-foreground);
  background-color: inherit;
  display: inline-block;
  text-decoration: none;
  margin-bottom: 0px;
  text-align: left;
}

a:hover > h2 {
  color: #cbd2d3;
  color: var(--dl-color-custom-main-background);
  margin-bottom: 0px;
}

h1,
h2,
h3 {
  padding: 5px;
  color: #cbd2d3;
  color: var(--dl-color-custom-main-background);
  background-color: #000;
  background-color: var(--dl-color-custom-foreground);
  font-weight: 700;
  width: auto;
  display: inline-block;
}

p {
  margin-top: 2rem;
  font-size: 1.6rem;
}

blockquote {
  border-left: 2px solid #000;
  border-left: 2px solid var(--dl-color-custom-foreground);
  padding-left: 10px;
  overflow-x: scroll;
}

.landingImage {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

figcaption {
  text-align: center;
  display: block;
  margin-top: 1rem;
  font-size: 1.4rem;
}

article {
  text-align: left;
}

video {
  width: 100%;
}

.splitPost {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.splitPost > p {
  flex: 1 1;
}

.splitPost > div {
  flex: 1 1;
}

.splitPost > figure {
  flex: 1 1;
}

.bold {
  font-weight: bold;
}

@media (max-width: 991px) {
  .splitPost {
    display: block;
  }
  .landingImage {
    height: auto;
  }
}

.style_smallImage__2D5mM {
  width: 45px;
  height: auto;
  display: inline-block;
}

.style_userName__3QDVz {
  display: inline-block;
  font-weight: normal;
  margin: 0px 0px 0px 15px;
  font-size: 1.6rem;
}

.style_navWrapper__3Oim7 {
  display: flex;
  padding: 8px;
  flex-wrap: wrap;
  justify-content: space-around;
  min-height: 60px;
  align-items: center;
}

.style_navList__3CRCX {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.style_listItem__2v4Bk > a {
  font-size: 1.8rem;
  text-decoration: none;
  font-weight: 700;
  color: var(--dl-color-custom-foreground);
  padding: 10px 20px;
}

.style_listItem__2v4Bk > a:hover {
  color: var(--dl-color-custom-main-background);
}

.gallery_girid-wrapper__3-MNY {
  display: grid;
  position: relative;
  margin-top: 5px;
  will-change: transform;
  grid-row-gap: 5px;
  grid-column-gap: 5px;
  grid-template-columns: repeat(3, 1fr);
}
.gallery_grid-item__1cnjA {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image__3MduH {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item01__2wvUR {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image01__16QvE {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item02__3Z5lh {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image02__1KwJH {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item03__8-J1y {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image03__24GGO {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item04__3vVIR {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image04__XMD79 {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item05__2tTvO {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image05__1Kzru {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item06__2JyVI {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image06__Ex51w {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item07__2V_oV {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image07__5MrVq {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item08__3GHBp {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image08__-hBAr {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item09__3C5hW {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image09__3pmDG {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item10__20bw- {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image10__6w7aF {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item11__3LCmy {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image11__BrIsN {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item12__1BT1A {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image12__WZC44 {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item13__17QPR {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image13__1h1DW {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item14__2GaXk {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image14__NTShu {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item15__3tSOq {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image15__afMyK {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item16__2egxu {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image16__oFZbQ {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item17__3sDcp {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image17__2mTud {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item18__3-6Zh {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image18__2jHBH {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item19__u0xPG {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image19__2p_SF {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item20__EFr9i {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image20__Hvr8N {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item21__22KQC {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image21__Hn6RX {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item22__3NMGL {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image22__3Vl3q {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item23__crZFN {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image23__2_2Mg {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item24__3NuBz {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image24__jnVTr {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item25__WxpAr {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image25__1TOGT {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item26__cw_pA {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image26__OWfkd {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item27__3GXNp {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image27__2jiUk {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item28__lIMpv {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image28__3dLl4 {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item29__1PDKY {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image29__3fW-T {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item30__2N4AO {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image30__1gWG1 {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item31__2Vw_a {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image31__11KvC {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item32__OUWaM {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image32__3AYVW {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item33__2WxjQ {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image33__2Sfwb {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item34__GcjUB {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image34__2H_9f {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item35__2x08h {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image35__2UE5W {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item36__Ywsow {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image36__1u6kl {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item37__ABjW5 {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image37__Q_Yeh {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item38__23pnN {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image38__1bPbF {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item39__1uwXJ {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image39__1veeP {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item40__EolmA {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image40__21p7h {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item41__3Hr6i {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image41__1LYKU {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item42__C6hhl {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image42__xVTAA {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}
.gallery_grid-item43__1dgCN {
  width: auto;
  height: 350px;
  display: block;
  overflow: hidden;
  position: relative;
  background-color: #dddddd;
}
.gallery_image43__1ob-A {
  width: 100%;
  height: 350px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  object-fit: cover;
  object-position: center;
}

.style_headWrapper__3eSnN {
  padding: 25px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.style_intro__3u8By {
  max-width: 500px;
  text-align: center;
}

.style_App-logo__3pXMV {
  -webkit-animation: style_App-logo-spin__3Xgo6 infinite 20s linear;
          animation: style_App-logo-spin__3Xgo6 infinite 20s linear;
  height: 40vmin;
}

.style_App-header__3yFyK {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.style_App-link__qhkxU {
  color: #61dafb;
}

.style_landingBanner__3MrMU {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.style_landingInvite__8Vydk {
  display: flex;
  max-width: 65%;
  height: 80vh;
  margin: auto;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.style_landingInvite__8Vydk > figure {
  flex: 1 1;
}

.style_landingInvite__8Vydk > figure > img {
  max-width: 250px;
  height: auto;
  margin-right: auto;
  margin-left: auto;
  display: inherit;
  -webkit-filter: grayscale(10);
          filter: grayscale(10);
}

.style_landingInvite__8Vydk > div {
  flex: 2 1;
}

@-webkit-keyframes style_App-logo-spin__3Xgo6 {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes style_App-logo-spin__3Xgo6 {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.style_card__3HtQW {
  background-color: #ffffff;
  color: #000000;
  border-radius: 6px;
  margin: 10px;
  display: flex;
  padding: 5px;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .style_landingInvite__8Vydk {
    flex-direction: column;
    max-width: 90%;
    margin-top: 30%;
  }

  .style_landingInvite__8Vydk > figure > img {
    max-width: 250px;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    display: inherit;
  }
}

.style_suggestion__1r0ua {
  font-size: 1.4rem;
}

.style_post_wrapper__2SNJ2 {
  width: 60%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 100px;
  line-height: 1.5;
}

.style_date__2p4fz {
  font-size: 1.4rem;
  padding-bottom: 10px;
  border-bottom: 0.3px solid var(--dl-color-custom-content);
}

.style_post_description__1nZat {
  margin-bottom: 10px;
}

.style_post_image_holder__3TOrG {
  position: relative;
}

.style_post_landing_loader__17-hO {
  top: 0px;
  z-index: 1;
  width: 100%;
  height: 400px;
  margin-top: 15px;
  position: absolute;
  background-color: #f4f4f4;
}

.style_post_landing_image__3b0YO {
  width: 100%;
  margin-top: 15px;
  object-fit: cover;
  object-position: top;
  max-height: 400px;
  object-fit: cover;
  object-position: center;
}

.style_post_content__25JOX {
  margin-top: 20px;
  padding-bottom: 30px;
  max-width: 80%;
  margin: auto;
}

@media (max-width: 767px) {
  .style_post_wrapper__2SNJ2 {
    width: 90%;
    padding: 0% 5%;
  }

  .style_post_content__25JOX {
    margin-top: 20px;
    padding-bottom: 30px;
    max-width: 90%;
    margin: auto;
  }
}

.style_blogDescription___-8Ij {
  text-align: left;
}

.style_aritcleGrid__3JyDe {
  display: grid;
  text-align: left;
  grid-gap: 40px;
  grid-template-columns: 1fr 1fr;
}

.style_blogsWrapper__3bAZx {
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 100px;
  width: 50%;
}

.style_landingImage__Ybjr_ {
  width: 100%;
  height: 350px;
  object-fit: cover;
}

.style_blogsHeading__3pTRn {
  text-align: center;
  font-size: 1.6rem;
}

.style_blogsDescription__2MEfT {
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 768px) {
  .style_aritcleGrid__3JyDe {
    grid-template-columns: 1fr;
  }

  .style_blogsWrapper__3bAZx {
    width: 80%;
  }
}

.style_container__2CcTO {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: var(--dl-color-custom-main-background);
  max-width: 70%;
  margin-right: auto;
  margin-left: auto;
}
.style_container01__2luWo {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.style_container02__38Y9y {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.style_text__1-XLU {
  color: var(--dl-color-custom-content);
  font-size: 2rem;
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-unit);
}
.style_image__HLius {
  width: 497px;
  height: 97px;
  object-fit: cover;
}
.style_container03__1Iff7 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.style_text01__3SKHg {
  color: var(--dl-color-custom-content);
}
.style_text07__2jZo- {
  font-weight: 700;
}
.style_container04__nDGMB {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.style_image1__1DmnU {
  width: 100%;
  -webkit-filter: grayscale(100%) brightness(70%);
          filter: grayscale(100%) brightness(70%);
  object-fit: cover;
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
}
.style_container05__1NZiz {
  width: 100%;
  margin-top: var(--dl-space-space-doubleunit);
}
.style_text13__5Mtps {
  color: var(--dl-color-custom-foreground);
  width: 100%;
  font-size: 2rem;
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-doubleunit);
  text-transform: uppercase;
}
.style_text14__2XYID {
  color: var(--dl-color-custom-content);
}
.style_container06__2Rqt2 {
  flex: 1 1;
  width: 100%;
  -webkit-filter: grayscale(100%) brightness(70%);
          filter: grayscale(100%) brightness(70%);
  height: 300px;
  display: flex;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.style_container07__Hj4kS {
  flex: 1 1;
  height: 300px;
}

.style_image2__3Uz3N {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.style_container08__gcw3S {
  flex: 1 1;
  height: 300px;
}

.style_image3__1a3DE {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.style_container09__cTb8a {
  flex: 1 1;
  height: 300px;
}

.style_image4__C-05d {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.divider_divider__29pUL {
    flex: 0 0 auto;
    width: 100%;
    border: 2px dashed rgba(120, 120, 120, 0.4);
    height: 1px;
    display: flex;
    margin-top: var(--dl-space-space-halfunit);
    align-items: flex-start;
    border-color: var(--dl-color-standard-custom-background);
    background-color: var(--dl-color-standard-custom-background);
    border-style: solid;
    border-width: 1px;
    flex-direction: row;
  }
  
.quote_container__Alp9r {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 65px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  background-color: var(--dl-color-custom-main-background);
}
.quote_container1__1gi6g {
  width: 10px;
  height: 65px;
  max-height: 100px;
  align-items: flex-start;
  background-color: var(--dl-color-custom-foreground);
}
.quote_text__2j2kZ {
  width: 80%;
  align-self: center;
  margin-left: var(--dl-space-space-doubleunit);
}

.design2code_container__2gRP0 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: flex-start;
  background-color: var(--dl-color-custom-main-background);
}
.design2code_container1__WIrEH {
  width: 65%;
  display: flex;
  max-width: 1200px;
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  justify-content: flex-start;
}
.design2code_text__3aMOC {
  color: var(--dl-color-custom-foreground);
}
.design2code_text01__1pZBY {
  font-size: 12px;
}
.design2code_text02__tgrCa {
  color: var(--dl-color-standard-custom-background);
  margin-top: var(--dl-space-space-halfunit);
}
.design2code_container2__2pNsU {
  flex: 0 0 auto;
  width: 100%;
  height: 400px;
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.design2code_image__1YnXP {
  flex: 1 1;
  width: 50%;
  height: 90%;
  object-fit: cover;
}
.design2code_image1__3C5Gh {
  flex: 1 1;
  width: 50%;
  height: 90%;
  object-fit: cover;
}
.design2code_text03__22Vqv {
  margin-bottom: var(--dl-space-space-unit);
}
.design2code_text05__3C8WV {
  font-weight: 700;
}
.design2code_text07__24KoK {
  width: 100%;
  margin-bottom: var(--dl-space-space-unit);
}
.design2code_text08__XiLlx {
  color: var(--dl-color-standard-custom-background);
  margin-bottom: var(--dl-space-space-unit);
}
.design2code_text10__1Nv9T {
  color: var(--dl-color-custom-foreground);
}
.design2code_text15__2TPCV {
  color: var(--dl-color-custom-foreground);
}
.design2code_text17__2ThnM {
  margin-bottom: var(--dl-space-space-unit);
}
.design2code_text33__22kh_ {
  margin-bottom: var(--dl-space-space-unit);
}
.design2code_text35__3ox7G {
  color: var(--dl-color-custom-foreground);
  font-weight: 700;
}
.design2code_container3__hITA1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: flex-start;
}
.design2code_image2__CNad7 {
  width: 250px;
  object-fit: cover;
}
.design2code_text37__3q2oF {
  height: 100%;
  margin-left: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-unit);
}
.design2code_text41__fEYwn {
  font-style: italic;
}
.design2code_text42__VMxsc {
  color: var(--dl-color-custom-foreground);
  font-style: italic;
}
.design2code_text43__1bqL0 {
  font-style: italic;
}
.design2code_container4__2jaKH {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.design2code_container5__1W6rk {
  flex: 1 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.design2code_text48__3zsKr {
  margin-bottom: var(--dl-space-space-unit);
}
.design2code_text50__2s19i {
  font-weight: 700;
}
.design2code_text52__1-hSP {
  font-weight: 700;
}
.design2code_text62__DeoaZ {
  font-weight: 700;
}
.design2code_text64__2or5H {
  font-weight: 700;
}
.design2code_text76__8Oyof {
  width: 100%;
  margin-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
}
@media(max-width: 991px) {
  .design2code_container1__WIrEH {
    width: 85%;
  }
}
@media(max-width: 479px) {
  .design2code_container1__WIrEH {
    width: 100%;
  }
  .design2code_container3__hITA1 {
    flex-wrap: wrap;
  }
  .design2code_text37__3q2oF {
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
  }
}

.es-build_container__2ZPdX {
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: flex-start;
  background-color: var(--dl-color-custom-main-background);
}
.es-build_container1__3fype {
  flex: 0 0 auto;
  width: 65%;
  height: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: column;
}
.es-build_text__3K-64 {
  color: var(--dl-color-custom-foreground);
}
.es-build_text001__2uObL {
  font-size: 12px;
  margin-bottom: var(--dl-space-space-halfunit);
}
.es-build_container2__3l69l {
  flex: 0 0 auto;
  width: 100%;
  height: 200px;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.es-build_image__3iLWp {
  width: 200px;
  object-fit: cover;
  margin-right: var(--dl-space-space-doubleunit);
}
.es-build_text002__949kA {
  margin-left: var(--dl-space-space-doubleunit);
  margin-right: var(--dl-space-space-doubleunit);
}
.es-build_text003__1hRY7 {
  margin-left: var(--dl-space-space-doubleunit);
}
.es-build_text004__2g9Mx {
  align-self: flex-start;
}
.es-build_text006__xzTi6 {
  color: var(--dl-color-custom-foreground);
  font-style: italic;
}
.es-build_text008__3P1RP {
  font-style: italic;
}
.es-build_text010__3W2Ta {
  font-style: italic;
}
.es-build_text016__2LwK4 {
  color: var(--dl-color-custom-foreground);
  font-style: italic;
}
.es-build_text018__2iIeQ {
  align-self: flex-start;
  margin-top: var(--dl-space-space-doubleunit);
}
.es-build_text020__1S0Vx {
  color: var(--dl-color-custom-foreground);
  font-style: italic;
}
.es-build_text021__3KelN {
  font-style: italic;
}
.es-build_text022__qrJxI {
  color: var(--dl-color-custom-foreground);
  font-style: italic;
}
.es-build_text023__1mSEs {
  font-style: italic;
}
.es-build_text025__cfI0a {
  font-style: italic;
}
.es-build_text026__2ytNV {
  color: var(--dl-color-custom-foreground);
  font-style: italic;
}
.es-build_text028__3mmM_ {
  align-self: flex-start;
  margin-top: var(--dl-space-space-doubleunit);
}
.es-build_text030__rFg93 {
  font-style: italic;
}
.es-build_text034__1UgB2 {
  font-weight: 700;
}
.es-build_text038__3mjgM {
  font-style: italic;
}
.es-build_text041__3uVWo {
  color: var(--dl-color-custom-foreground);
  font-style: italic;
}
.es-build_text045__1YlE_ {
  color: var(--dl-color-custom-foreground);
  margin-top: var(--dl-space-space-doubleunit);
}
.es-build_text046__3QXq0 {
  align-self: flex-start;
  margin-top: var(--dl-space-space-unit);
}
.es-build_text053__P5zzo {
  color: var(--dl-color-custom-foreground);
}
.es-build_text055__34fuB {
  font-style: italic;
}
.es-build_text057__c6L9o {
  color: var(--dl-color-custom-foreground);
}
.es-build_image1__3k2dl {
  width: 100%;
  object-fit: cover;
}
.es-build_text063__Q_2o- {
  align-self: flex-start;
  margin-top: var(--dl-space-space-doubleunit);
}
.es-build_text065__1yHIt {
  color: var(--dl-color-custom-foreground);
  font-weight: 700;
}
.es-build_text067__1emFn {
  font-style: italic;
}
.es-build_text070__O5xXJ {
  color: var(--dl-color-custom-content);
}
.es-build_text071__KcyEz {
  align-self: flex-start;
  margin-top: var(--dl-space-space-doubleunit);
}
.es-build_text073__3qWP7 {
  color: var(--dl-color-custom-foreground);
  font-style: italic;
}
.es-build_text079__2jblV {
  font-style: italic;
}
.es-build_text081__NacpG {
  color: var(--dl-color-custom-foreground);
  font-style: italic;
}
.es-build_text088__1WPXW {
  font-style: italic;
}
.es-build_text090__1OezU {
  font-style: italic;
}
.es-build_text092__2FiJw {
  color: var(--dl-color-custom-foreground);
}
.es-build_text093__1eB3S {
  color: var(--dl-color-custom-foreground);
  font-style: italic;
}
.es-build_text094__2LcXo {
  color: var(--dl-color-custom-foreground);
}
.es-build_text095__fV5Qs {
  color: var(--dl-color-custom-foreground);
  font-style: italic;
}
.es-build_text096__Can07 {
  color: var(--dl-color-custom-foreground);
}
.es-build_text097__OQMiV {
  color: var(--dl-color-custom-foreground);
  font-style: italic;
}
.es-build_text099__AHWml {
  color: var(--dl-color-custom-foreground);
  margin-top: var(--dl-space-space-doubleunit);
}
.es-build_text100__3t-ji {
  margin-left: var(--dl-space-space-halfunit);
}
.es-build_text104__2Xxjf {
  font-style: italic;
}
.es-build_iframe__1mYU6 {
  width: 100%;
  height: 400px;
  margin-top: var(--dl-space-space-unit);
}
.es-build_text106__n9lhO {
  margin-top: var(--dl-space-space-doubleunit);
  margin-left: var(--dl-space-space-halfunit);
}
.es-build_iframe1__UmPxp {
  width: 100%;
  height: 400px;
  margin-top: var(--dl-space-space-unit);
}
.es-build_text107__3Mtg9 {
  align-self: flex-start;
  margin-top: var(--dl-space-space-unit);
}
.es-build_text109__3PTyJ {
  font-style: italic;
}
.es-build_text111__22OIY {
  font-style: italic;
}
.es-build_iframe2__99h-5 {
  width: 100%;
  height: 200px;
}
.es-build_text113__1O6SD {
  width: 100%;
  align-self: flex-start;
}
.es-build_text119__3wvq0 {
  font-style: italic;
}
.es-build_text121__3UboR {
  font-style: italic;
}
.es-build_text123__2E1cZ {
  font-style: italic;
}
.es-build_image2__3VKGN {
  width: 100%;
  margin-top: var(--dl-space-space-doubleunit);
  object-fit: cover;
}
.es-build_text127__2tS5s {
  margin-top: var(--dl-space-space-unit);
}
.es-build_text131__iM8eN {
  color: var(--dl-color-custom-foreground);
}
.es-build_text132__1_5AQ {
  color: var(--dl-color-custom-foreground);
}

@media(max-width: 767px) {
  .es-build_container1__3fype {
    width: 90%;
  }
  
  .es-build_container2__3l69l {
    flex-wrap: wrap;
    margin-top: 15px;
    margin-bottom: 15px;
    height: auto;
    flex-direction: column;
    align-items: center;
  }

  .es-build_image__3iLWp {
    margin-right: 0px;
  }

  .es-build_text003__1hRY7 {
    margin-left: 0px;
  }
}

.style_map__wuwsg {
  width: 100%;
}

.style_map__wuwsg > div {
  height: 100%;
}

.style_portfolio__2eWZf {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  height: calc(100vh - 60px);
}

.style_portfolio__2eWZf > div {
  flex-basis: 0;
  flex-grow: 1;
  height: 100%;
}

.style_bigPicture__G8sUe {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.style_user__3T_EC {
  width: 175px;
  height: auto;
}

.style_travelDescription__1B9ev {
  width: 70%;
  font-size: 1.6rem;
}

@media (max-width: 767px) {
  .style_portfolio__2eWZf {
    display: block;
  }
  .style_user__3T_EC {
    display: none;
  }
}

