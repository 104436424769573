.headWrapper {
  padding: 25px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.intro {
  max-width: 500px;
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.landingBanner {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.landingInvite {
  display: flex;
  max-width: 65%;
  height: 80vh;
  margin: auto;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.landingInvite > figure {
  flex: 1;
}

.landingInvite > figure > img {
  max-width: 250px;
  height: auto;
  margin-right: auto;
  margin-left: auto;
  display: inherit;
  filter: grayscale(10);
}

.landingInvite > div {
  flex: 2;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card {
  background-color: #ffffff;
  color: #000000;
  border-radius: 6px;
  margin: 10px;
  display: flex;
  padding: 5px;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .landingInvite {
    flex-direction: column;
    max-width: 90%;
    margin-top: 30%;
  }

  .landingInvite > figure > img {
    max-width: 250px;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    display: inherit;
  }
}
