.divider {
    flex: 0 0 auto;
    width: 100%;
    border: 2px dashed rgba(120, 120, 120, 0.4);
    height: 1px;
    display: flex;
    margin-top: var(--dl-space-space-halfunit);
    align-items: flex-start;
    border-color: var(--dl-color-standard-custom-background);
    background-color: var(--dl-color-standard-custom-background);
    border-style: solid;
    border-width: 1px;
    flex-direction: row;
  }
  