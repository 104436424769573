.map {
  width: 100%;
}

.map > div {
  height: 100%;
}

.portfolio {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  height: calc(100vh - 60px);
}

.portfolio > div {
  flex-basis: 0;
  flex-grow: 1;
  height: 100%;
}

.bigPicture {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.user {
  width: 175px;
  height: auto;
}

.travelDescription {
  width: 70%;
  font-size: 1.6rem;
}

@media (max-width: 767px) {
  .portfolio {
    display: block;
  }
  .user {
    display: none;
  }
}
